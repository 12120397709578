.Myaccount-bg-1{padding: 30px 0px 100px 0px;;}
.Myaccount-bg-1 h3{font-style: italic; font-weight: 500; font-size: 16px;;}
.Myaccount-bg-1 h4{ font-weight: 400; font-size: 14px;;text-decoration: underline;}
.Myaccount-bg-1 h6{ font-weight:300; font-size: 14px; margin:14px 0px 20px 0px;;}
.Myaccount-bg-1 h5{ font-weight:400; font-size: 16px; text-decoration: underline;}
.Refer-Earn b{border: 1px dashed #5140D5;padding: 5px 10px; 
display: inline-block; vertical-align: middle; margin-left: 10px; border-radius: 5px; color: #5140D5;}
.Address-wrap{display: flex; width: 100%; justify-content: space-between; margin: 15px 0px 20px 0px;}
.Address-box{width: 32%;position: relative;}

.Address-bg{position: relative; padding: 10px 20px;  border: 1px solid #5140D5; border-radius: 5px;box-shadow: 0px 0px 25px #00000029; margin-bottom: 10px;;}

.Address-box-2{width: 32%;position: relative;    }
hr {
  margin: 10px 0px !important;
}

.Address-edit-icon{ position: absolute; width: 20px;height: 20px; right: 10px; top: 10px;;}
.Address-edit-icon img{ width: 80%;height: auto;}
.Myaccount-bg-1 h1 span{margin: 0px 0px 0px 10px;color: #5140D5; font-weight: 300; font-size: 13px; vertical-align: middle;}
.Myaccount-bg-1 h1 img{margin: 0px 6px 0px 0px;color: #5140D5; font-weight: 300; font-size: 13px;;}
.heading-2{margin: 0px 0px 20px 0px; color: #5140D5; font-weight: 500;}
.TockensButton{border: 1px solid #5140D5;padding: 3px 8px; font-size:13px; background-color: #fff; border-radius: 5px;;}
.editfield{width:140px; padding: 5px 10px; margin: 0px 0px 0px 10px;}
.editbutton{ border: 0px; padding: 0px 10px; background-color: transparent;}
 
.country-code-1{border:1px solid #ccc;padding: 4px 10px; font-size: 14px; width: 50px;;}
.text-field-2{ border: 1px solid #ccc; min-width: 200px; font-size: 14px;padding: 4px 10px;}
.TockensButton{ white-space: nowrap;}
.AccountPopupField{display: flex; width: 100%;}
.font-style-2{margin: 0px 0px 20px 0px; color: #5140D5; font-weight: 500;}
.font-style-2 b{margin: 0px 0px 20px 0px; color: #000; font-weight: 500;}
.font-style-2 span{width:100px; display: inline-block;}
@media screen and (max-width:830px){
    .Address-wrap{flex-wrap: wrap;}
    .Address-box {
        width: 100%;
        margin-bottom: 16px;;    padding: 10px;
    }
    .Address-box-2 {
      width: 100%;
      margin-bottom: 5px;;    padding: 0px;
  }
    .Myaccount-bg-1 {
        padding: 20px 0px 90px 0px;
    }
    .Address-wrap {
       
        margin: 10px 0px 10px 0px;
    }

}


/*Invite Popup CSS*/
.InvitePopupmodal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index:99999
  }
  
  .InvitePopupmodal.open {
    display: flex;
  }
  
  .InvitePopupmodal-content {
    background-color: #fff;
    padding: 30px 20px;
    border-radius: 8px;
    position: relative;
  }
  
  .InvitePopupclose {
    position: absolute;
    top: -5px;
    right: -10px;
    font-size: 20px;
    cursor: pointer;
    width:30px; height:30px; text-align:center;border:1px solid #ccc; border-radius:100px; background-color: #fff;
  }
  .invite-textfield-wrap{margin: 10px 0px;;}
  .AccountPopupField{margin: 10px 0px;;}

