.puzzle-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

#puzzle-container {
    width: 280px;
    height: 280px;
    /* border: 1px solid #ccc; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 3px;
}

.emptycell{background-color: #fff !important;}
.puzzle-piece {
    /* border: 1px solid #999;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s; */

    border: 1px solid #5240d5;
    background-color: #fcfcff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #5240d5;


}

.puzzle-piece:hover {
    background-color: #5240d5;
    color: #fff !important;
}

.puzzle-piece-mt{ background-color: #fff !important;}
.info {
    margin-top: 20px;
    font-size: 18px;
}
.game-content-main{background-color:#ebe9ff; padding: 10px 10px; width: 100%;
    max-width: 1000px; margin: 20px auto; display:flex; border-radius: 10px; border:1px soliod #ccc;}
    .gc-1{ padding: 0px 20px 0px 0px;;}
    .gc-1 b{  color: #5140d5;;}
@media screen and (max-width:830px){
    #puzzle-container{width:280px;height: 280px;;}
}