.profile-bg-1{padding: 30px 0px;;}
.profile-sec-main{display: flex; width: 100%; margin: 25px 0px 0px 0px;}
.profile-sec-1{width: 300px; position: relative;height: fit-content;}
.profile-sec-2{flex:1;}
.profile-sec-2 h4{font-size: 24px;font-weight: 500; margin:0px; padding: 10px 0px 0px 0px;;}
.profile-sec-2 h5{font-style: italic; font-weight: 500; font-size: 16px;;}
.verification{padding: 10px 0px;color: #333333; font-weight: 300; font-size: 13px;;}
.verification span{margin: 0px 0px 0px 10px;color: #5140D5; font-weight: 300; font-size: 13px;;}
.verification span img{margin: 0px 6px 0px 0px;color: #5140D5; font-weight: 300; font-size: 13px;;}
.ProfilePic{width: 200px;height: 200px; object-fit: cover; border-radius: 100px;;
border:1px solid #ccc}



.ProfilepEdit-main{position: relative; width: 200px;;}
.ProfilepEdit{position: absolute; width: 30px; height: 30px; right: 0px; bottom: 30px;}
.ProfilepEdit img{ width: 100%; height: auto; cursor: pointer;}
.custom-file-input {
    color: transparent;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select some files';
    color: black;
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
  }
  

.image-upload > input {  display: none; }
.image-upload img { width: 32px;  cursor: pointer; }
.profileActions{position: absolute; bottom:20px; right: 36%; width: 32px; height: 32px;;}
.profileActions img{width:100%; height: auto;}


.profile-info-wrap{background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px #00000029;
  border-radius: 7px; padding:10px; margin: 20px 0px; display:flex; width:100%; flex-wrap:wrap;}

  .piw-1{ font-size: 12px; width: 23%; padding:10px;} 
  .w-100{ width:100% !important}
@media screen and (max-width:830px){
  .profile-sec-main{flex-wrap: wrap; width: 100%;}
  .profile-sec-1{ width: 100%; text-align: center; max-width: 300px; margin: 0 auto;}
  .profile-sec-2 {
    flex: inherit;
    width: 100%;
}
.profileActions {
  position: absolute;
  bottom: 20px;
  right: 20%;
  width: 32px;
  height: 32px;
}
.verification, .profile-sec-2 h4, .profile-sec-2 h5{text-align: center;}
  
}




