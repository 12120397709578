.Login-Bg{position: fixed; width: 100%; height: 100vh; background-color: rgba(0,0,0,0.5); z-index: 9999;}
.Login-wrap{width: 96%; max-width:500px; padding: 0px; /*background-color: #fff; */margin: 0 auto; position: absolute;border-radius: 5px;; left: 50%; top: 50%; transform: translate(-50%, -50%);}
.Login-Header-Text{background-color: #5140D5; padding: 10px 10px;-webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px; -moz-border-radius-topleft: 5px; -moz-border-radius-topright: 5px; border-top-left-radius: 5px; border-top-right-radius: 5px; text-align: center;}
.Login-Content-wrap{padding: 20px;}
.Login-Header-Text h2{ color:#fff; font-size: 30px; text-transform: uppercase;}
.Login-Header-Text h6{ color:#fff; font-size: 16px; font-weight: 400;}
.PhoneInput{border: 1px solid #707070;padding: 0px 5px 0px 10px;border-radius: 5px;;}
.PhoneInputInput{border:0px; border-left: 1px solid #707070; padding: 5px 10px; width: 100%; box-sizing: border-box; outline: none;} 
.Login-Content-wrap p{ margin: 0px; padding: 0px 0px 8px 0px;}
.Popup-Close{ position: absolute; z-index: 9999;;height:20px; width: 20px; background-color: #ff0000; color:#fff; right: 10px; top:10px;}
.closePopup1{position: absolute; z-index: 9999;;height:20px; width: 20px;  color:#fff; right: 20px; top:5px;}
.closePopup1 button{background-color: transparent; border: 0px; font-size: 16px;;}
.button-2{ background-color: #5140D5; padding: 5px 10px; color:#fff; font-size: 18px; border: 0px; text-transform: uppercase; ;}
.button-2:hover{ background-color: #5140D5; }
#recaptcha-container{ position: fixed !important; bottom: 0px !important; right: 0px !important;;}
@media screen and (max-width:830px){
    .login-2{order: 1; text-align: center; flex: inherit; width: 100%;height: auto; margin: 0px 0px 20px 0px;;}
    .login-2 img{ height:auto; width: 80%;}
    .login-1{order: 2; flex: inherit; width: 100%;text-align: center;}
    .login-1 h1{font-size: 18px;;}
    .login-bg { height: 88vh; background-position: top center;  background-repeat: no-repeat;  background-size: cover; }
    .btn-3 {  font-size: 16px;  padding: 3px 8px;  }
}


.Login-wrap form{
    
   background-color: #fff;
    position: relative;
 -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px; -moz-border-radius-topleft: 5px; -moz-border-radius-topright: 5px; border-top-left-radius: 5px; border-top-right-radius: 5px;  
   
    animation: zoomIn 1.5s ease forwards;
    opacity: 0;
  }
  
  @keyframes zoomIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }


  .Login-Bg { animation: fadeIn 0.5s; }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }