.Contact-wrap{padding: 30px 0px;;}
.Contact-wrap{ width: 96%; max-width:1170px;margin: 0 auto;}
.Contact-form-wrap{display: flex;width: 100%; max-width:800px; padding: 20px; flex-wrap:wrap; 
    justify-content: space-between; margin: 0 auto;  margin: 0px 0px 0px 0px;box-shadow: 0px 0px 25px #00000029; border-radius: 5px;;}
.reg-1{ width: 45%; padding: 0px 0px 20px 0px;}
.reg-1 b{font-weight: 300; font-size: 14px; margin: 0px 0px 6px 0px; display: block;  color:#333333}
.w-100{width: 100% !important;}
.Contact-button{background-color: #5140D5; padding: 5px 20px; color: #fff; font-family: 'Nunito', sans-serif;
    border: 0px;  margin:20px 0px 0px 0px; border-radius: 5px; text-transform: uppercase;}
.error{color : red}
    .contact-address-wrap{display: flex; width: 100%; margin: 30px 0px; flex-wrap: wrap;}
    .contact-address-1{width: 30%;}
    .contact-address-1 h4{font-size: 20px;margin:0px 0px 20px 0px; text-decoration: none;;}
    .contact-address-2{flex:1;}

    @media screen and (max-width:830px){
        .reg-1 {
            width: 100%;
            padding: 0px 0px 10px 0px;
        }
        
    }
        
    @media screen and (max-width:600px){
        .contact-address-1{width: 100%;}
        .contact-address-2{width: 100%; margin-top: 10px;}
    }
   