.footer-bg{ background-image: url(../images/footer.png); /*position: fixed;bottom: 0px; */ width: 100%;background-repeat: no-repeat;background-position: center top; background-size: cover; padding: 46px 0px 10px 0px; text-align: center; font-size: 14px;;}
.FooterArrow{position: absolute; left: 50%; top: -6px;;}
.relative-1{position: relative;}
.footerlinks{ margin: 12px 0px;;}
.footerlinks ul{margin: 0px; padding: 0px; list-style: none;;}
.footerlinks ul li{margin: 0px 20px; padding: 0px; list-style: none; display: inline-block; }
.footerlinks ul li a{ color: #000; text-decoration: none;}

.rotate {
    transition: transform 0.3s ease;
  }
  
  .rotated {
    transform: rotate(180deg);
  }
  
  .cursor-pointer {
    cursor: pointer;
  }

