.DashboardSlider-bg-1{padding: 30px 0px 60px 0px;;}

.Winnings-wrap{display: flex; width: 100%; margin: 25px 0px 40px 0px; flex-wrap: wrap;}

.border-1 img{width: 100%; height: auto;;}
.DashboardSlider-bg-1 h3{ margin:10px; padding: 0px; text-align:left; font-size: 16px;;}
.DashboardSlider-bg-1 h6{ margin:10px 0px; font-size: 14px; text-align:left; color: #5240d5; font-weight: 400;}
.DashboardSlider-bg-1 p{ margin:10px; font-size: 14px; text-align:left;;}

.DashboardSlider .slick-next:before{  content: url(../images/right-arrow.png) !important; }
.DashboardSlider .slick-prev:before { content: url(../images/left-arrow.png) !important; }
.border-1{border: 1px solid #ccc; width:84% !important; text-align: center; margin: 0 auto;padding:20px; height: 100%; border-radius: 10px;;}
.DashboardSlider .slick-slider {text-align: center;}
.TermsConditionsBox{padding: 5px 0px 10px 0px;}
.TermsConditionsBox input{ vertical-align: middle; margin-right: 5px;;}

.DashboardSlider-bg-1 ul{ margin: 0px; padding: 0px; list-style: none;;;}
.DashboardSlider-bg-1 ul li{ margin: 0px 0px 10px 14px; font-size: 15px;; padding: 0px; list-style: circle;;;}

.paragraph-1 p{ margin: 0px 0px 15px 0px; font-size: 15px;; padding: 0px;;}
/* Overlay for the popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Popup content styling */
.popup-content {
  background-color: #fff;
  text-align: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  position: absolute;
  padding: 20px;
}

/* Close button styling */
.popup-content button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.form-group {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
  }
  
  .form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #5240d5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 7px;
    width: 6px;
    height: 10px;
    border: solid #5240d5;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .onClickCancel{
    background-color: #fff;
    border: none;
    margin-top: 2%;
  }
.paragraph-1 img{max-width: 100%; height: auto;}
.block-sec{display: block;width: 100%;;}

.video-wrap-main{ width: 100%; display: flex;}
.video-wrap-1{ width: 60%; padding-right: 15px;;}
.video-wrap-2{  flex:1; background-color: #573030;padding: 10px 20px; border: 1px solid #ccc;}
.video-wrap-2 iframe{ border: 0px; max-height: 250px;;}
.video-wrap-2 h4{ margin:0px 0px 14px 0px;padding: 0px;color: #5240d5; font-size: 16px;;}
@media screen and (max-width:830px){
  
  .popup-content p {
    font-size: 13px;
    
  }

  .popup-content {
    max-width: 91%;
    max-height: 95%;
    overflow-y: auto; /* Enable vertical scroll */
    overflow-x: hidden; /* Prevent horizontal scroll */
    padding: 16px; /* Optional: Add some padding for better presentation */
    box-sizing: border-box; /* Ensures padding doesn't affect width/height */
}

}

@media screen and (max-width:767px){
.video-wrap-main{  flex-wrap: wrap;}
.video-wrap-1{ width:100%; padding-right: 0px;;}
.video-wrap-2{ width: 100%; flex:inherit; margin-top: 14px;;}
.popup-content p {
  font-size: 13px;
 
}
}
@media screen and (max-width:600px){
    

    
    }

.offline-container{
  display: flex;
  justify-content: center;
}
.offline-card{
  display: column;
  margin-bottom: 50px;
  /* justify-content: center; */
}
.offline-wrap-1{
  width: 100%;padding-right: 15px;
}
.custom-toaster{
  top : 10%
}
#_rht_toaster {
  margin-top: 140px !important; 
  inset: 16px !important; 
  z-index: 9999; 
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
}

.popup-content img {
  width: 200px; /* Adjust image size as necessary */
  height: auto;
}

.close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #ff0000;
  color: white;
  border: none;
  cursor: pointer;
}
.congrats-text {
  text-align: center;
  color: #28a745; /* Green color */
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.congratulations-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.congratulations-img {
  max-width: 100%;
  width: 300px;
  margin-bottom: 20px;
}

.result-img {
  max-width: 100%;
  width: 250px;
  margin-top: 20px;
}

.congrats-text {
  color: #28a745;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.failure-text {
  color: #dc3545;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.btn-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-2 {
  padding: 12px 24px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
}

.btn-2:hover {
  background-color: #0056b3;
}
