.login-bg{width:100%;background-image: url(../images/login-bg-1.png); height: 80vh;padding-top: 120px;    background-position: bottom center;
    background-repeat: no-repeat; background-size: cover;}
.login-wrap{display: flex; width: 100%; flex-wrap: wrap;}
.login-1{flex:1;    margin: auto;padding-right: 5%;}
.login-2{ width: auto; margin: auto; height: 60vh;}
.login-2 img{ width: auto; height: 100%; max-width:100%; object-fit:cover}
.login-1 h1{color: #fff; width: 96%; max-width: 500px;}
.btn-secondary{text-transform: uppercase; ;}
#recaptcha-container{margin: 20px 0px 0px 0px;}
/*
.button-2{ background-color: #5140D5; padding: 5px 10px; color:#fff; font-size: 18px; border: 0px; text-transform: uppercase; ;}

@media screen and (max-width:830px){
    .login-2{order: 1; text-align: center; flex: inherit; width: 100%;height: auto; margin: 0px 0px 20px 0px;;}
    .login-2 img{ height:auto; width: 80%;}
    .login-1{order: 2; flex: inherit; width: 100%;text-align: center;}
    .login-1 h1{font-size: 18px;;}
    .login-bg { height: 88vh; background-position: top center;  background-repeat: no-repeat;  background-size: cover; }
    .btn-3 {  font-size: 16px;  padding: 3px 8px;  }
}
*/