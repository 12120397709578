.ChangeAddress-wrap{padding: 30px 0px;;}
.ChangeAddress-wrap{ width: 96%; max-width:800px;margin: 0 auto;}
.ChangeAddress-form-wrap{display: flex;width: 100%; max-width:800px; padding: 20px; flex-wrap:wrap; 
    justify-content: space-between; margin: 0 auto;  margin: 30px 0px 0px 0px;box-shadow: 0px 0px 25px #00000029; border-radius: 5px;;}
.reg-1{ width: 45%; padding: 0px 0px 20px 0px;}
.reg-1 b{font-weight: 300; font-size: 14px; margin: 0px 0px 6px 0px; display: block;  color:#333333}
.w-100{width: 100% !important;}
.ChangeAddress-button{background-color: #5140D5; padding: 5px 20px; color: #fff; font-family: 'Nunito', sans-serif;
    border: 0px;  margin:20px 0px 0px 0px; border-radius: 5px; text-transform: uppercase;}

 
    @media screen and (max-width:830px){
        .reg-1 {
            width: 100%;
            padding: 0px 0px 10px 0px;
        }
        
    }
        

   