.ChangeSubscriptions-wrap{display: flex; width: 100%; justify-content: space-between; margin: 30px 0px 80px 0px;}
.ChangeSubscriptions-box-1{flex:1;position: relative; padding: 20px;  border-radius: 5px;text-align: left; box-shadow: 0px 0px 25px #00000029; background-color: #fff; margin-right: 20px;;}
.ChangeSubscriptions-box-2{width: 30%;position: relative;   padding: 20px; }

.ChangeSubscriptions-wrap ul{ margin: 20px 0px;padding: 0px; list-style: none; ; display: block;}
.ChangeSubscriptions-wrap ul li{  font-size:14px; margin: 0px;padding: 0px 0px 10px 20px;background-image: url(../images/check-icon.png); background-repeat: no-repeat;background-position: left top 8px;text-align: left; }
.Subscriptions-box h3{margin:10px 0px; text-transform: uppercase;}

.ChangeSubscriptions-box-1 a{color:#5140D5; text-decoration: underline; }

.tab-button {
    /* Your default button styles */
    border-radius: 100px;
    border: none;
    padding: 8px 16px;
    margin-right: 8px;
    cursor: pointer;
  }
  
  .tab-button.active {
    /* Your active button styles */
    background-color: #000;
    color: white;
    border-radius: 100px;
  }
  .tab-content{padding: 20px 0px;;}
  .tab-content h3{ color: #000;}
  .tab-content h3 b{ text-decoration: line-through; padding: 0px 20px; color: #ccc !important;}
  .btn-bg-1{background-color: #F2EFFD; display: inline-block; border-radius: 100px;;}

@media screen and (max-width:1024px){
   
}
@media screen and (max-width:830px){

    .ChangeSubscriptions-wrap{flex-wrap: wrap;}
    .ChangeSubscriptions-box-1{width: 100%; margin-bottom: 15px;    margin-right: 0px;;}
    .ChangeSubscriptions-box-2{width: 100%; margin-bottom: 15px;;}
}
@media screen and (max-width:600px){
  
}