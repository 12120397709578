.Winnings-bg-1 {
  padding: 30px 0px;
}
.Winnings-bg-1 h6 {
  font-weight: 300;
  font-size: 14px;
  margin: 14px 0px 20px 0px;
}
h3 {
  font-size: 28px;
  text-transform: uppercase;
}
.Winnings-wrap {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  margin: 25px 0px 40px 30px;
  flex-wrap: wrap;
}
.Winnings-box {
  width: 32%;
  position: relative;
  padding: 14px;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0px 0px 25px #00000029;
  display: flex;
  justify-content: space-between;
  margin: 0px 11px 20px 0px;
}
.Winnings-box:hover {
  border: 1px solid #5140d5;
}
.wb-1 {
  width: 60px;
}
.wb-1 img {
  width: 100%;
  height: auto;
}
.wb-2 {
  width: 80px;
  padding-left: 10px;
  font-weight: 500;
  margin: auto;
}
.wb-3 {
  flex: 1;
  font-size: 14px;
}
.Select-Contest {
  width: 96%;
  max-width: 400px;
}
.Select-Contest select {
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 5px;
}
.Winnings-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 99999;
}
.Winnings-popup-content {
  background-color: #fff;
  text-align: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  position: absolute;
  padding: 20px;
}

.congratulations-sec {
  text-align: center;
}
.congratulations-sec img {
  max-width: 80%;
  height: auto;
}
.clear {
  clear: both;
}
@media screen and (max-width: 830px) {
  .Winnings-wrap {
    flex-wrap: wrap;
  }
  .Winnings-box {
    width: 48%;
  }
  h1 {
    font-size: 28px;
    text-transform: uppercase;     
  }
  .Winnings-popup-content {
    max-width: 80%;
  }
  .Winnings-popup-content p {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .Winnings-box {
    flex: inherit;
    font-size: 14px;
    width: 100%;
  }
}
.entries-box{
  width: 32%;
  position: relative;
  padding: 14px;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0px 0px 25px #00000029;
  margin: 0px 11px 20px 0px;
}

.display-image{
  width: 100%;
  height: 15vh;
}

@media screen and (max-width: 600px) {
  .entries-box {
    flex: inherit;
    font-size: 14px;
    width: 100%;
  }
}